@import url("./theme.scss");

.mobile-menu.on {
  z-index: 99;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #c7c3c3;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}

.input-error {
  border: 2px solid #dc3545 !important;
}

.upload-image-preview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  
  .image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 3px;
    border: 1px dashed black;
  }
}

.video-modal{
  max-width: 450px;
}
.thumbnail{
  height: 50px;
  width: 50px;
}

// checkbox switch
.form-switch .form-check-input {
  height: 22px;
  width: 48px;
}
.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input{
  background-color: #ff0000;
  border-color: #ff0000;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}
.form-switch .form-check-input:checked {
  background-color: #30D158;
  border-color: #30D158;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}